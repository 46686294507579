<template>
  <div>
    <b-button
      class="btn-block btn-outline-secondary text-dark payment-option-btn"
      variant="outline-primary"
      @click="$emit('update:value', !isOpen)"
    >
      <img
        :src="NetworksLogos.INSTAPAY"
        alt="InstaPay"
        height="20"
      >
    </b-button>
    <b-collapse
      id="collapse-payment-method-instapay"
      :visible="isOpen"
    >
      <b-card class="border mb-0">
        <b-card-text
          class="card-text"
          v-html="$t('modules.purchases._create.payment_options.INSTAPAY.description', {amount: amount.value})"
        />

        <b-overlay
          :show="isLoading"
          rounded="sm"
          variant="secondary"
          opacity="0.75"
        >
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <!-- Body -->
            <b-form
              @submit.prevent="handleSubmit(handlePaymentSubmit)"
              @reset.prevent="() => {}"
            >
              <b-row>
                <b-col
                  cols="12 pb-2"
                >
                  <label>
                    {{ $t('modules.purchases._create.payment_options.INSTAPAY.account') }}
                  </label>
                  <b-input-group>
                    <b-form-input
                      value="kssib.egypt@instapay"
                      :readonly="true"
                    />

                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click="copyAction('instapay')"
                        @mouseleave="isCopiedFlags.instapay = false"
                      >
                        <feather-icon
                          :icon="isCopied('instapay') ? 'CheckIcon' : 'CopyIcon'"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col
                  cols="12"
                  md="9"
                >
                  <i-input
                    id="itemName"
                    ref="itemName"
                    v-model="paymentReference"
                    :errors="[]"
                    type="text"
                    title="modules.purchases.inputs.payment_reference"
                    placeholder="############"
                    maxlength="12"
                    :validation-rules="['required', 'numeric', 'min:12']"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="align-bottom align-content-center text-right"
                >
                  <b-button
                    variant="primary"
                    block
                    type="submit"
                  >
                    {{ $t('modules.purchases.actions.checkout') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <p
            v-b-modal.modal-instapay-tutorial
            class="cursor-pointer text-info mb-0"
          >
            <feather-icon
              icon="HelpCircleIcon"
              size="21"
              class="mr-75"
            />
            <span class="align-middle">{{ $t('modules.purchases._create.payment_options.INSTAPAY.tutorial') }}</span>
          </p>
          <b-modal
            id="modal-instapay-tutorial"
            centered
            title=""
            hide-footer
          >
            <b-card-text>
              <img
                :src="INSTAPAY_TUTORIAL_IMG"
                alt=""
                style="height: 100%; width: auto; max-width: 100%"
              >
            </b-card-text>
          </b-modal>
        </b-overlay>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import {
  BCollapse,
  BCard,
  BCardText,
  VBToggle,
  BButton,
  BForm,
  BOverlay,
  BRow,
  BCol,
  BModal, BInputGroup, BInputGroupAppend, BFormInput, VBModal,
} from 'bootstrap-vue'
import { ref } from 'vue'
import { NetworksLogos } from '@/views/models/payment_methods/list/PaymentMethodItem.vue'
import { ValidationObserver } from 'vee-validate'

const INSTAPAY_TUTORIAL_IMG = require('@/assets/images/tutorials/instapay_payment_reference.jpg')

export default {
  components: {
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    BCol,
    BRow,
    BOverlay,
    ValidationObserver,
    BForm,
    BButton,
    // BSV
    BCard,
    BCardText,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  emits: ['payment-submit'],
  props: {
    amount: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCopiedFlags: {
        instapay: false,
      },
      paypalSdk: null,
      loadedPaypalPaymentButtons: [],
    }
  },
  watch: {
    paymentDetails() {
      // this.loadPaypalPaymentMethods()
    },
  },
  async mounted() {
    //
  },
  methods: {
    copyAction(attr) {
      if (attr === 'instapay') {
        this.$copyText('kssib.egypt')
      }
      this.isCopiedFlags[attr] = true
      setTimeout(() => {
        this.isCopiedFlags[attr] = false
      }, 2000)
    },
    isCopied(attr) {
      return this.isCopiedFlags[attr] || false
    },
  },
  setup(props, ctx) {
    const refFormObserver = ref(null)
    const paymentReference = ref(null)
    const isLoading = ref(false)
    const handlePaymentSubmit = () => {
      // isLoading.value = true
      ctx.emit('payment-submit', {
        code: 'INSTAPAY',
        payload: {
          id: paymentReference.value,
        },
        callback: () => {
          // isLoading.value = false
        },
      })
    }

    return {
      refFormObserver,
      isLoading,
      paymentReference,
      handlePaymentSubmit,

      NetworksLogos,
      INSTAPAY_TUTORIAL_IMG,
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-option-btn {
  border: 1px solid #555 !important;
  border-radius: 4px;
}
@media only screen and (min-width: 75px) {
  .payment-option-btn {
    height: 25px;
    vertical-align: top;
    min-height: 25px;
    max-height: 30px;
  }
}

@media only screen and (min-width: 150px) {
  .payment-option-btn {
    height: 25px;
    vertical-align: top;
    min-height: 25px;
    max-height: 55px;
  }
}
@media only screen and (min-width: 200px) {
  .payment-option-btn {
    height: 35px;
    vertical-align: top;
    min-height: 35px;
    max-height: 55px;
  }
}
@media only screen and (min-width: 300px) {
  .payment-option-btn {
    height: 45px;
    vertical-align: top;
    min-height: 30px;
    max-height: 55px;
  }
}

@media only screen and (min-width: 500px) {
  .payment-option-btn {
    height: 55px;
    vertical-align: top;
    min-height: 40px;
    max-height: 55px;
  }
}
</style>
