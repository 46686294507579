<template>
  <div>
    <b-button
      class="btn-block btn-outline-secondary text-dark payment-option-btn"
      variant="outline-primary"
      @click="$emit('update:value', !isOpen)"
    >
      <img
        :src="NetworksLogos.MEEZA_MOBILE"
        alt="Meeza Wallets"
        height="30"
        class="border-right border-3"
      >
      <img
        :src="NetworksLogos.EG_MOBILE_WALLETS"
        alt="Egypt Mobile Wallets"
        height="30"
      >
    </b-button>
    <b-collapse
      id="collapse-payment-method-PAYMOB_EG_MOBILE_WALLETS"
      :visible="isOpen"
    >
      <b-card class="border mb-0">
        <b-card-title
          class="card-text"
          v-text="$t('modules.purchases._create.payment_options.PAYMOB_EG_MOBILE_WALLETS.subtitle')"
        />
        <b-card-text
          class="card-text"
          v-html="$t('modules.purchases._create.payment_options.PAYMOB_EG_MOBILE_WALLETS.description', {amount: amount.value})"
        />

        <b-overlay
          :show="isLoading"
          rounded="sm"
          variant="secondary"
          opacity="0.75"
        >
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <!-- Body -->
            <b-form
              @submit.prevent="handleSubmit(handlePaymentSubmit)"
              @reset.prevent="() => {}"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="9"
                >
                  <i-input
                    id="itemName"
                    ref="itemName"
                    v-model="paymentMobile"
                    :errors="[]"
                    type="text"
                    title="modules.purchases.inputs.mobile_wallet_number"
                    placeholder="01XXXXXXXXX"
                    maxlength="11"
                    :validation-rules="['required', 'numeric', 'min:11']"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  class="align-bottom align-content-center text-right"
                >
                  <b-button
                    variant="primary"
                    block
                    type="submit"
                  >
                    {{ $t('modules.purchases.actions.checkout') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import {
  BCollapse,
  BCard,
  BCardText,
  VBToggle,
  BButton,
  BForm,
  BOverlay,
  BRow,
  BCol,
  VBModal, BCardTitle,
} from 'bootstrap-vue'
import { ref } from 'vue'
import { NetworksLogos } from '@/views/models/payment_methods/list/PaymentMethodItem.vue'
import { ValidationObserver } from 'vee-validate'

const EG_MOB_WALLETS_TUTORIAL_IMG = require('@/assets/images/tutorials/instapay_payment_reference.jpg')

export default {
  components: {
    BCardTitle,
    BCol,
    BRow,
    BOverlay,
    ValidationObserver,
    BForm,
    BButton,
    // BSV
    BCard,
    BCardText,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  emits: ['payment-submit'],
  props: {
    amount: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      //
    }
  },
  watch: {
    paymentDetails() {
      // this.loadPaypalPaymentMethods()
    },
  },
  async mounted() {
    //
  },
  methods: {
    //
  },
  setup(props, ctx) {
    const refFormObserver = ref(null)
    const paymentMobile = ref(null)
    const isLoading = ref(false)
    const handlePaymentSubmit = () => {
      // isLoading.value = true
      ctx.emit('payment-submit', {
        code: 'PAYMOB_EG_MOB_WALLETS',
        payload: {
          payment_mobile: paymentMobile.value,
        },
        callback: () => {
          // isLoading.value = false
        },
      })
    }

    return {
      refFormObserver,
      isLoading,
      paymentMobile,
      handlePaymentSubmit,

      NetworksLogos,
      EG_MOB_WALLETS_TUTORIAL_IMG,
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-option-btn {
  border: 1px solid #555 !important;
  border-radius: 4px;
}
@media only screen and (min-width: 75px) {
  .payment-option-btn {
    height: 25px;
    vertical-align: top;
    min-height: 25px;
    max-height: 30px;
  }
}

@media only screen and (min-width: 150px) {
  .payment-option-btn {
    height: 25px;
    vertical-align: top;
    min-height: 25px;
    max-height: 55px;
  }
}
@media only screen and (min-width: 200px) {
  .payment-option-btn {
    height: 35px;
    vertical-align: top;
    min-height: 35px;
    max-height: 55px;
  }
}
@media only screen and (min-width: 300px) {
  .payment-option-btn {
    height: 45px;
    vertical-align: top;
    min-height: 30px;
    max-height: 55px;
  }
}

@media only screen and (min-width: 500px) {
  .payment-option-btn {
    height: 55px;
    vertical-align: top;
    min-height: 40px;
    max-height: 55px;
  }
}
</style>
