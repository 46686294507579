<template>
  <div>
    <b-button
      class="btn-block btn-outline-secondary text-dark payment-option-btn"
      variant="outline-primary"
      @click="$emit('update:value', !isOpen)"
    >
      <img
        :src="NetworksLogos.VISA_MASTERCARD_MEEZA"
        alt="Visa Mastercard Meeza"
        height="25"
      >
    </b-button>
    <b-collapse
      id="collapse-payment-method-PAYMOB_VISA_MASTERCARD_MEEZA"
      :visible="isOpen"
    >
      <b-card class="border mb-0">
        <b-card-title
          class="card-text"
          v-text="$t('modules.purchases._create.payment_options.VISA_MASTERCARD_MEEZA.subtitle')"
        />
        <b-card-text
          class="card-text"
          v-html="$t('modules.purchases._create.payment_options.VISA_MASTERCARD_MEEZA.description', {amount: amount.value})"
        />

        <b-button
          variant="primary"
          block
          :loading="isLoading"
          @click="handlePaymentSubmit"
        >
          {{ $t('modules.purchases.actions.checkout') }}
          <feather-icon :icon="$i18n.locale === 'ar' ? 'ChevronLeftIcon' : 'ChevronRightIcon'" />
        </b-button>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import {
  BCollapse,
  BCard,
  BCardText,
  VBToggle,
  BButton,
  VBModal,
  BCardTitle,
} from 'bootstrap-vue'
import { ref } from 'vue'
import { NetworksLogos } from '@/views/models/payment_methods/list/PaymentMethodItem.vue'

export default {
  components: {
    BCardTitle,
    BButton,
    // BSV
    BCard,
    BCardText,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  emits: ['payment-submit'],
  props: {
    amount: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  watch: {
    paymentDetails() {
    },
  },
  async mounted() {
    //
  },
  methods: {
  },
  setup(props, ctx) {
    const refFormObserver = ref(null)
    const isLoading = ref(false)
    const handlePaymentSubmit = () => {
      // isLoading.value = true
      ctx.emit('payment-submit', {
        code: 'PAYMOB_VISA_MASTERCARD_MEEZA',
        payload: {},
        callback: () => {
          // isLoading.value = false
        },
      })
    }

    return {
      refFormObserver,
      isLoading,
      handlePaymentSubmit,

      NetworksLogos,
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-option-btn {
  border: 1px solid #555 !important;
  border-radius: 4px;
}
@media only screen and (min-width: 75px) {
  .payment-option-btn {
    height: 25px;
    vertical-align: top;
    min-height: 25px;
    max-height: 30px;
  }
}

@media only screen and (min-width: 150px) {
  .payment-option-btn {
    height: 25px;
    vertical-align: top;
    min-height: 25px;
    max-height: 55px;
  }
}
@media only screen and (min-width: 200px) {
  .payment-option-btn {
    height: 35px;
    vertical-align: top;
    min-height: 35px;
    max-height: 55px;
  }
}
@media only screen and (min-width: 300px) {
  .payment-option-btn {
    height: 45px;
    vertical-align: top;
    min-height: 30px;
    max-height: 55px;
  }
}

@media only screen and (min-width: 500px) {
  .payment-option-btn {
    height: 55px;
    vertical-align: top;
    min-height: 40px;
    max-height: 55px;
  }
}
</style>
