<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    variant="secondary"
    opacity="0.75"
  >
    <div class="list-view product-checkout mt-0">

      <!-- Left Card -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>{{ $t('modules.purchases._create.payment') }}</b-card-title>
          <b-card-text class="text-muted mt-25">
            {{ $t('modules.purchases._create.payment_subtitle') }}
          </b-card-text>
        </b-card-header>
        <b-card-body>

          <div
            id="paypal-card-button-container"
            class="d-block"
          />
          <div
            id="paypal-applepay-button-container"
            class="d-block"
          />
          <div
            id="paypal-paypal-button-container"
            class="d-block"
          />
          <Instapay
            v-if="'INSTAPAY' in paymentDetails.payment_methods"
            :amount="paymentDetails.payment_methods.INSTAPAY.amount"
            :is-open="isPaymentMethodBtnOpened.INSTAPAY"
            @update:value="v => isPaymentMethodBtnOpened.INSTAPAY = v"
            @payment-submit="payload => $emit('payment-submit', payload)"
          />
          <PaymobVisaMatercardMeeza
            v-if="'PAYMOB_VISA_MASTERCARD_MEEZA' in paymentDetails.payment_methods"
            :amount="paymentDetails.payment_methods.PAYMOB_VISA_MASTERCARD_MEEZA.amount"
            :is-open="isPaymentMethodBtnOpened.PAYMOB_VISA_MASTERCARD_MEEZA"
            class="mt-50"
            @update:value="v => isPaymentMethodBtnOpened.PAYMOB_VISA_MASTERCARD_MEEZA = v"
            @payment-submit="payload => $emit('payment-submit', payload)"
          />
          <PaymobEgMobWallets
            v-if="'PAYMOB_EG_MOB_WALLETS' in paymentDetails.payment_methods"
            :amount="paymentDetails.payment_methods.PAYMOB_EG_MOB_WALLETS.amount"
            :is-open="isPaymentMethodBtnOpened.PAYMOB_EG_MOB_WALLETS"
            class="mt-50"
            @update:value="v => isPaymentMethodBtnOpened.PAYMOB_EG_MOB_WALLETS = v"
            @payment-submit="payload => $emit('payment-submit', payload)"
          />
          <b-alert
            v-if="!paymentDetails.payment_methods || !Object.keys(paymentDetails.payment_methods).length"
            variant="warning"
            show
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                v-t="`modules.purchases._messages.no_payment_methods_available`"
                class="ml-25"
              />

              <b-link
                v-b-modal.support-modal
                class="alert-link"
              >
                {{ $t('modules.purchases.actions.request_support') }}
              </b-link>
            </div>
          </b-alert>
        <!--        <hr class="my-2">-->
        <!--        <div id="paypal-button-container" />-->

        <!--        <p class="cursor-pointer">-->
        <!--          <feather-icon-->
        <!--            icon="PlusCircleIcon"-->
        <!--            size="21"-->
        <!--            class="mr-75"-->
        <!--          />-->
        <!--          <span class="align-middle">Add Gift Card</span>-->
        <!--        </p>-->
        </b-card-body>
      </b-card>

      <!-- Right Card -->
      <div class="amount-payable checkout-options">
        <b-card
          :title="$t('modules.purchases._create.price_details')"
          class="position-sticky"
        >

          <ul class="list-unstyled price-details">
            <li class="price-detail">
              <div class="details-title">
                {{ $t('modules.purchases._create.subtotal') }}
              </div>
              <div class="detail-amt">
                <strong>
                  <template v-if="paymentDetails.summary.subtotal.currency === 'USD'">
                    {{ paymentDetails.summary.subtotal.string }}
                  </template>
                  <money
                    v-else
                    :value="paymentDetails.summary.subtotal.amount"
                    :currency="paymentDetails.summary.subtotal.currency"
                  />
                </strong>
              </div>
            </li>
            <li class="price-detail">
              <div class="details-title">
                {{ $t('modules.purchases._create.payment_charges') }}
              </div>
              <div class="detail-amt discount-amt text-success">
                {{ $t('Free') }}
              </div>
            </li>
            <li
              v-if="paymentDetails.summary.discount"
              class="price-detail"
            >
              <div class="details-title">
                {{ $t('modules.purchases._create.discount') }}
              </div>
              <div class="detail-amt discount-amt text-success">
                <template v-if="paymentDetails.summary.discount.currency === 'USD'">
                  {{ paymentDetails.summary.discount.string }}
                </template>
                <money
                  v-else
                  :value="paymentDetails.summary.discount.amount"
                  :currency="paymentDetails.summary.discount.currency"
                />
              </div>
            </li>
          </ul>
          <hr>
          <ul class="list-unstyled price-details">
            <li class="price-detail">
              <div class="details-title">
                {{ $t('modules.purchases._create.amount_payable') }}
              </div>
              <div class="detail-amt font-weight-bolder">
                <template v-if="paymentDetails.summary.total.currency === 'USD'">
                  {{ paymentDetails.summary.total.string }}
                </template>
                <money
                  v-else
                  :value="paymentDetails.summary.total.amount"
                  :currency="paymentDetails.summary.total.currency"
                />
              </div>
            </li>
          </ul>

        </b-card>
      </div>
    </div>
  </b-overlay>
</template>

<script>
// import { loadScript } from '@paypal/paypal-js'
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  VBToggle,
  BOverlay,
  BAlert, BLink, VBModal,
} from 'bootstrap-vue'
import { ref } from 'vue'
import { NetworksLogos } from '@/views/models/payment_methods/list/PaymentMethodItem.vue'
import { toast } from '@core/utils/utils'
import PaymobVisaMatercardMeeza from '@/views/models/purchases/create/payment_methods/PaymobVisaMatercardMeeza.vue'
import Instapay from '@/views/models/purchases/create/payment_methods/Instapay.vue'
import PaymobEgMobWallets from '@/views/models/purchases/create/payment_methods/PaymobEgMobWallets.vue'

// const PAYPAL_CLIENT_ID = 'AVnFEzBHXt25o8VZcbUkeBgwqZPy75v90mN6BdeJ-7zvYiyAoX193ezL2gzwDuq5yxNR4gMTvfiGv0U9'
// const PAYPAL_CLIENT_ID = 'sb'

export default {
  components: {
    PaymobEgMobWallets,
    Instapay,
    PaymobVisaMatercardMeeza,
    BLink,
    BAlert,
    BOverlay,
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  emits: ['payment-submit'],
  props: {
    paymentDetails: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCopiedFlags: {
        instapay: false,
      },
      paypalSdk: null,
      loadedPaypalPaymentButtons: [],
    }
  },
  watch: {
    paymentDetails() {
      // this.loadPaypalPaymentMethods()
    },
  },
  async mounted() {
    // this.paypalSdk = await loadScript({
    //   'client-id': PAYPAL_CLIENT_ID,
    //   currency: this.paymentDetails.summary.currency_code,
    //   // applepay: true,
    //   // buyerCountry: 'US', // Only in sandbox
    //   locale: this.$i18n.locale === 'ar' ? 'ar_EG' : 'en_EG',
    //   // debug: true,
    // })

    // this.loadPaypalPaymentMethods()
  },
  methods: {
    loadPaypalPaymentMethods() {
      // Close previous loaded buttons
      while (this.loadedPaypalPaymentButtons.length) {
        this.loadedPaypalPaymentButtons.pop().close()
      }

      // Create new buttons
      Object.keys(this.paymentDetails.payment_methods).forEach(o => {
        const { amount } = this.paymentDetails.payment_methods[o]
        if (o.startsWith('paypal')) {
          // paypalSdk.FUNDING.PAYPAL, paypalSdk.FUNDING.CARD, paypalSdk.FUNDING.APPLEPAY
          this.createPaypalButton(this, this.paypalSdk, o.substring(7), amount)
        }
      })
    },
    copyAction(attr) {
      if (attr === 'instapay') {
        this.$copyText('kssib.egypt')
      }
      this.isCopiedFlags[attr] = true
      setTimeout(() => {
        this.isCopiedFlags[attr] = false
      }, 2000)
    },
    isCopied(attr) {
      return this.isCopiedFlags[attr] || false
    },
    createPaypalButton(ctx, paypalSdk, fundingSource, amount) {
      const config = {
        fundingSource,
        // enableStandardCardFields: true,
        onClick(data, actions) {
          ctx.closeLocalPayments()
        },
        // onCancel(data, actions) {
        //   console.log('onCancel', data, actions)
        // },
        createOrder(data, actions) {
          console.log('createOrder', data, actions)
          return actions.order.create({
            payer: {
              name: {
                given_name: ctx.paymentDetails.payer.firstName,
                surname: ctx.paymentDetails.payer.lastName,
              },
              address: {
                address_line_1: ctx.paymentDetails.payer.address_line_1,
                address_line_2: ctx.paymentDetails.payer.address_line_2,
                admin_area_2: ctx.paymentDetails.payer.admin_area_2,
                admin_area_1: ctx.paymentDetails.payer.admin_area_1,
                postal_code: ctx.paymentDetails.payer.postal_code,
                country_code: ctx.paymentDetails.payer.country_code,
              },
              email_address: ctx.paymentDetails.payer.email_address,
              phone: {
                phone_type: 'MOBILE',
                phone_number: {
                  national_number: ctx.paymentDetails.payer.mobile?.replace('+', ''),
                },
              },
            },
            purchase_units: [{
              // invoice_id: '550e8400-e29b-41d4-a716-446655440000',
              // items: [{
              //   name: 'Kssib Basic Subscription',
              //   quantity: 1,
              //   unit_amount: {
              //     currency_code: 'USD',
              //     value: 5,
              //   },
              //   category: 'DIGITAL_GOODS',
              // }],
              amount: {
                currency_code: amount.currency_code,
                value: amount.value,
                // breakdown: {
                //   item_total: {
                //     currency_code: 'USD',
                //     value: 5,
                //   },
                // },
              },
            }],
            application_context: {
              shipping_preference: 'NO_SHIPPING',
            },
          })
        },
        onError(err) {
          toast('danger', ctx.$t('message.operation_failed'), String(err), 'AlertTriangleIcon')
        },
        onApprove(data, actions) {
          return actions.order.capture().then(details => {
            console.log('onApprove', details)
            alert(`Transaction completed by ${details.payer.name.given_name}!`)
          })
        },
        style: {
          disableMaxWidth: true,
          label: 'paypal',
          tagline: false,
          color: 'white',
        },
      }
      const button = paypalSdk.Buttons(config)
      if (button.isEligible()) {
        button.render(`#paypal-${fundingSource}-button-container`)
        this.loadedPaypalPaymentButtons.push(button)
        return true
      }
      return false
    },
  },
  setup(props, ctx) {
    const refFormObserver = ref(null)
    const isPaymentMethodBtnOpened = ref({
      INSTAPAY: false,
      PAYMOB_VISA_MASTERCARD_MEEZA: false,
      PAYMOB_EG_MOB_WALLETS: false,
    })
    const closeLocalPayments = () => {
      isPaymentMethodBtnOpened.INSTAPAY.value = false
      isPaymentMethodBtnOpened.PAYMOB_VISA_MASTERCARD_MEEZA.value = false
      isPaymentMethodBtnOpened.PAYMOB_EG_MOB_WALLETS.value = false
    }

    const isPaymentMethodSelected = paymentMethod => isPaymentMethodBtnOpened[paymentMethod] || false
    const togglePaymentMethodSelection = paymentMethod => {
      isPaymentMethodBtnOpened[paymentMethod] = !isPaymentMethodBtnOpened[paymentMethod]
    }

    return {
      refFormObserver,
      closeLocalPayments,
      isPaymentMethodSelected,
      isPaymentMethodBtnOpened,
      togglePaymentMethodSelection,

      NetworksLogos,
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-option-btn {
  border: 1px solid #555 !important;
  border-radius: 4px;
}
@media only screen and (min-width: 75px) {
  .payment-option-btn {
    height: 25px;
    vertical-align: top;
    min-height: 25px;
    max-height: 30px;
  }
}

@media only screen and (min-width: 150px) {
  .payment-option-btn {
    height: 25px;
    vertical-align: top;
    min-height: 25px;
    max-height: 55px;
  }
}
@media only screen and (min-width: 200px) {
  .payment-option-btn {
    height: 35px;
    vertical-align: top;
    min-height: 35px;
    max-height: 55px;
  }
}
@media only screen and (min-width: 300px) {
  .payment-option-btn {
    height: 45px;
    vertical-align: top;
    min-height: 30px;
    max-height: 55px;
  }
}

@media only screen and (min-width: 500px) {
  .payment-option-btn {
    height: 55px;
    vertical-align: top;
    min-height: 40px;
    max-height: 55px;
  }
}

.position-sticky {
  top: 100px;
}
</style>
