<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    variant="secondary"
    opacity="0.75"
  >
    <div class="list-view product-checkout mt-0">

      <!-- Products List -->
      <e-commerce-checkout-step-cart-products
        :items="checkoutDetails.items"
        @update-qty="payload => $emit('update-qty', payload)"
        @remove-product="payload => $emit('remove-product', payload)"
      />

      <!-- Checkout Options -->
      <div class="checkout-options">
        <b-card>

          <label class="section-label mb-1">{{ $t('modules.purchases._create.summary') }}</label>
          <div v-if="!showCouponInput && !isCouponCodeApplied">
            <a @click="showCouponInput = true">{{ $t('modules.purchases._create.do_you_have_coupon') }}</a>
          </div>
          <Transition name="fade-bottom">
            <b-input-group
              v-if="showCouponInput || isCouponCodeApplied"
              class="input-group-merge coupons font-weight-normal"
            >
              <b-form-input
                v-model="couponCode"
                :placeholder="$t('modules.purchases._create.enter_coupon')"
                :readonly="isCouponCodeApplied"
                class="px-1"
              />
              <b-input-group-append is-text>
                <span
                  v-if="isCouponCodeApplied"
                  id="input-coupons"
                  class="input-group-text text-danger cursor-pointer ml-50"
                  @click="$emit('remove-coupon')"
                >{{ $t('modules.purchases._create.remove_coupon') }}</span>
                <span
                  v-else
                  id="input-coupons"
                  class="input-group-text text-primary cursor-pointer"
                  @click="$emit('apply-coupon', couponCode)"
                >{{ $t('modules.purchases._create.apply_coupon') }}</span>
              </b-input-group-append>
            </b-input-group>
          </Transition>
          <hr>
          <div class="price-details">
            <!--          <h6 class="price-title">-->
            <!--            Price Details-->
            <!--          </h6>-->
            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="detail-title">
                  {{ $t('modules.purchases._create.subtotal') }}
                </div>
                <div class="detail-amt">
                  <template v-if="checkoutDetails.summary.subtotal.currency === 'USD'">
                    {{ checkoutDetails.summary.subtotal.string }}
                  </template>
                  <money
                    v-else
                    :value="checkoutDetails.summary.subtotal.amount"
                    :currency="checkoutDetails.summary.subtotal.currency"
                  />
                </div>
              </li>
              <li class="price-detail">
                <div class="detail-title">
                  {{ $t('modules.purchases._create.discount') }}
                </div>
                <div
                  v-if="checkoutDetails.summary.discount.amount"
                  class="detail-amt discount-amt text-success"
                >
                  <template v-if="checkoutDetails.summary.discount.currency === 'USD'">
                    {{ checkoutDetails.summary.discount.string }}
                  </template>
                  <money
                    v-else
                    :value="checkoutDetails.summary.discount.amount"
                    :currency="checkoutDetails.summary.discount.currency"
                  />
                </div>
                <div
                  v-else
                  class="detail-amt discount-amt"
                >-</div>
              </li>
              <!--            <li class="price-detail">-->
              <!--              <div class="detail-title">-->
              <!--                Payment Fees-->
              <!--              </div>-->
              <!--              <div class="detail-amt discount-amt text-success">-->
              <!--                Free-->
              <!--              </div>-->
              <!--            </li>-->
            </ul>
            <hr>
            <ul class="list-unstyled">
              <li class="price-detail">
                <div class="detail-title detail-total">
                  {{ $t('modules.purchases._create.total') }}
                  <small> ({{ $t('modules.purchases._create.tax_inclusive') }})</small>
                </div>
                <div class="detail-amt font-weight-bolder">
                  <template v-if="checkoutDetails.summary.total.currency === 'USD'">
                    {{ checkoutDetails.summary.total.string }}
                  </template>
                  <money
                    v-else
                    :value="checkoutDetails.summary.total.amount"
                    :currency="checkoutDetails.summary.total.currency"
                  />
                </div>
              </li>
            </ul>
            <b-button
              variant="primary"
              block
              @click="$emit('next-step')"
            >
              {{ $t('modules.purchases.actions.continue') }}
            </b-button>
          </div>

        </b-card>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BButton, BCard, BFormInput, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import ECommerceCheckoutStepCartProducts from './PurchaseCheckoutStepCartProducts.vue'

export default {
  components: {
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BOverlay,
    // BSV
    BButton,
    BCard,

    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  emits: ['update-qty', 'remove-product', 'apply-coupon', 'remove-coupon'],
  props: {
    checkoutDetails: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showCouponInput: false,
      couponCode: null,
    }
  },
  watch: {
    'checkoutDetails.coupon_code': function () {
      this.couponCode = this.couponCodeApplied
    },
  },
  computed: {
    couponCodeApplied() {
      return this.checkoutDetails?.coupon_code
    },
    isCouponCodeApplied() {
      return !!this.couponCodeApplied
    },
  },
}
</script>
