<template>
  <form-wizard
    ref="refFormWizard"
    color="#00c99c"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >
    <!-- purchase-items tab -->
    <tab-content
      :title="$t('modules.purchases._create._tabs.cart')"
      icon="feather icon-shopping-cart"
    >
      <e-commerce-checkout-step-cart
        :checkout-details="checkoutDetails"
        :is-loading="isLoading"
        @next-step="formWizardNextStep"
        @update-qty="payload => onClickCartItemQtyUpdate(payload)"
        @remove-product="payload => onClickCartItemRemove(payload)"
        @apply-coupon="couponCode => onClickApplyCouponSubmit(couponCode)"
        @remove-coupon="onClickApplyCouponSubmit(null)"
      />
    </tab-content>

    <!-- payment tab -->
    <tab-content
      v-if="checkoutDetails.items && checkoutDetails.items.length"
      :title="$t('modules.purchases._create._tabs.payment')"
      icon="feather icon-credit-card"
    >
      <e-commerce-checkout-step-payment
        :payment-details="checkoutDetails"
        :is-loading="isLoading"
        @next-step="formWizardNextStep"
        @payment-submit="onPaymentSubmit"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { onMounted, onUnmounted, ref } from 'vue'
import store from '@/store'
import purchaseStoreModule from '@/views/models/purchases/purchaseStoreModule'
import useModelUpdate from '@/views/models/common/useModelUpdate'
import { usePurchaseCart } from '@/views/models/purchases/usePurchaseCart'
import { isDevelopment, toast } from '@core/utils/utils'
import Router from '@/router'
import ECommerceCheckoutStepCart from './PurchaseCheckoutStepCart.vue'
import ECommerceCheckoutStepPayment from './PurchaseCheckoutStepPayment.vue'

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,

    // SFC
    ECommerceCheckoutStepCart,
    ECommerceCheckoutStepPayment,
  },
  mounted() {
    if (this.refFormWizard) {
      this.$nextTick(() => {
        this.refFormWizard.activateAll()
      })
    }
    // Set value of refs
    this.refs.value = this.$refs
  },
  setup() {
    const STORE_MODULE_NAME = 'purchases'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, purchaseStoreModule)
    onMounted(() => {
      if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, purchaseStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    const isLoading = ref(false)
    const checkoutDetails = ref({
      id: 'cart',
      summary: {
        subtotal: {
          currency: 'USD',
          amount: 5.0,
        },
        discount: {
          currency: 'USD',
          amount: 0,
        },
        total: {
          currency: 'USD',
          amount: 5.0,
        },
      },
      payer: {
        firstName: 'Firstname',
        lastName: 'Lastname',
        address_line_1: '123 ABC Street',
        address_line_2: 'Apt 2',
        admin_area_2: 'San Jose',
        admin_area_1: 'CA',
        country_code: 'EG',
        email_address: 'customer@domain.com',
        mobile: '14082508100',
      },
      payment_methods: {
        paypal_card: {
          amount: {
            currency_code: 'USD',
            value: 5.0,
          },
        },
        paypal_applepay: {
          amount: {
            currency_code: 'USD',
            value: 5.0,
          },
        },
        paypal_paypal: {
          amount: {
            currency_code: 'USD',
            value: 5.0,
          },
        },
        instapay: {
          amount: {
            currency_code: 'EGP',
            value: 250,
          },
        },

      },
      coupon_code: null,
      payment_method_code: null,
    })

    const refs = ref({})
    const { validationErrors, fetch, save } = useModelUpdate(
      STORE_MODULE_NAME,
      refs,
      checkoutDetails,
      () => {
        alert('SUCCESS')
      },
      null,
      null,
      () => {
        console.log('EXIT')
      },
    )

    isLoading.value = true
    fetch().finally(() => {
      isLoading.value = false
    })

    const {
      checkoutPurchase, updateProductQty, removeProductFromCart, applyCoupon,
    } = usePurchaseCart()

    const onPaymentSubmit = payload => {
      if (isDevelopment()) {
        console.log('PAYLOAD', payload)
      }
      isLoading.value = true
      checkoutPurchase(payload.code, payload.payload).then(response => {
        if (typeof (payload.callback) === 'function') {
          payload.callback(true)
        }
        if (isDevelopment()) {
          console.log('CONFIRMATION', response)
        }
        if (response.redirect) {
          window.location.href = response.redirect
        } else {
          Router.push({ name: 'purchases.confirmation', params: { id: response.data.id } })
        }
      }).catch(e => {
        payload.callback(false)
        toast('danger', 'Error', e.response?.data?.message || e.message)
      }).finally(() => {
        isLoading.value = false
        payload.callback(null)
      })
    }

    const onClickApplyCouponSubmit = coupon => {
      isLoading.value = true
      applyCoupon(coupon).then(response => {
        checkoutDetails.value = response
      }).catch(e => {
        toast('danger', 'Error', e.response?.data?.message || e.message)
      }).finally(() => {
        isLoading.value = false
      })
    }

    const onClickCartItemQtyUpdate = payload => {
      isLoading.value = true

      updateProductQty(payload.id, payload.qty).then(response => {
        checkoutDetails.value = response
      }).finally(() => {
        isLoading.value = false
      })
    }
    const onClickCartItemRemove = payload => {
      isLoading.value = true
      removeProductFromCart(payload).then(response => {
        checkoutDetails.value = response
      }).finally(() => {
        isLoading.value = false
      })
    }

    return {
      refs,
      refFormWizard,
      isLoading,
      formWizardNextStep,
      onPaymentSubmit,
      onClickApplyCouponSubmit,
      onClickCartItemQtyUpdate,
      onClickCartItemRemove,

      // Dummy Details
      checkoutDetails,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard';
@import '~@core/scss/base/pages/app-ecommerce';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
[dir] .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: 0 3px 6px 0 rgba(105, 255, 158, 0.4);
}
</style>
