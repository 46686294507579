<template>

  <div class="checkout-items">
    <b-card
      v-for="product in items"
      :key="product.id"
      class="ecommerce-card"
      no-body
    >

      <!-- Product Image -->
      <div class="item-img">
        <!--        <b-link>-->
        <!--          <b-img-->
        <!--            :src="product.image"-->
        <!--            :alt="`${product.name}-${product.id}`"-->
        <!--          />-->
        <!--        </b-link>-->
      </div>

      <!-- Product Details: Card Body -->
      <b-card-body>
        <div class="item-name">
          <h6 class="mb-0">
            <b-link class="text-body">
              {{ product.name }}
            </b-link>
          </h6>
          <!--          <span class="item-company">By <b-link class="company-name">{{ product.brand }}</b-link></span>-->
          <!--          <div class="item-rating">-->
          <!--            <ul class="unstyled-list list-inline">-->
          <!--              <li-->
          <!--                v-for="star in 5"-->
          <!--                :key="star"-->
          <!--                class="ratings-list-item"-->
          <!--                :class="{'ml-25': star-1}"-->
          <!--              >-->
          <!--                <feather-icon-->
          <!--                  icon="StarIcon"-->
          <!--                  size="16"-->
          <!--                  :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"-->
          <!--                />-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </div>-->
        </div>
        <!--        <span class="text-success mb-1">In Stock</span>-->
        <div class="item-quantity mt-1">
          <span class="quantity-title">{{ $t('Qty') }}:</span>
          <b-form-spinbutton
            v-model="product.qty"
            size="sm"
            class="ml-75"
            inline
            @input="$emit('update-qty', {id:product.purchasable_code, qty: product.qty})"
          />
        </div>
        <!--        <span class="delivery-date text-muted">Delivery by {{ formatDate(product.shippingDate, { month: 'short', day: 'numeric', weekday: 'short' }) }}</span>-->
        <!--        <span class="text-success">{{ product.discountPercentage }}% off {{ product.offers }} offers Available</span>-->
      </b-card-body>

      <!-- Product Options/Actions -->
      <div class="item-options text-center">
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              {{ product.price.string }}
            </h4>
            <!--            <p-->
            <!--              v-if="product.hasFreeShipping"-->
            <!--              class="card-text shipping"-->
            <!--            >-->
            <!--              <b-badge-->
            <!--                pill-->
            <!--                variant="light-success"-->
            <!--              >-->
            <!--                Free Shipping-->
            <!--              </b-badge>-->
            <!--            </p>-->
          </div>
        </div>
        <b-button
          variant="light"
          class="mt-1 remove-wishlist"
          @click="$emit('remove-product', product.purchasable_code)"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span>{{ $t('Remove') }}</span>
        </b-button>
        <!--        <b-button-->
        <!--          variant="primary"-->
        <!--          class="btn-cart move-cart"-->
        <!--          @click="toggleProductInWishlist(product)"-->
        <!--        >-->
        <!--          <feather-icon-->
        <!--            icon="HeartIcon"-->
        <!--            class="mr-50"-->
        <!--            :class="{'fill-current': product.isInWishlist}"-->
        <!--          />-->
        <!--          <span class="text-nowrap">Wishlist</span>-->
        <!--        </b-button>-->
      </div>
    </b-card>
    <b-alert
      v-if="!items || !items.length"
      variant="info"
      show="show"
    >
      <div class="alert-body">
        <span>
          {{ $t('modules.purchases._messages.cart_has_no_items') }}
        </span>
        <router-link :to="{name: 'plans'}">
          {{ $t('modules.purchases._messages.add_items') }}
        </router-link>
      </div>
    </b-alert>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BButton, BFormSpinbutton, BAlert,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BAlert,
    BCard,
    BCardBody,
    BLink,
    BButton,
    BFormSpinbutton,
  },
  emits: ['update-qty', 'remove-product'],
  props: {
    items: {
      type: Array,
      default: Array,
    },
  },
  watch: {
    items(newValue) {
      if (newValue.length < 1) {
        this.$router.push({ name: 'purchases.list' })
      }
    },
  },
  setup() {
    return {
      // Filter
      formatDate,
    }
  },
}
</script>

<style>

</style>
